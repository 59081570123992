:root {
    --primary-color: #3498db;
    --secondary-color: #2c3e50;
    --text-color: #333;
    --bg-color: #f4f4f4;
    --white: #ffffff;
  }
  
  .page-wrapper {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .smooth-scroll {
    scroll-behavior: smooth;
  }
  
  .main-content {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--white);
    padding-top: 72px;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  /* 头部样式 */
  .site-header {
    background-color: var(--secondary-color);
    color: var(--white);
    padding: 1em 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
  }
  
  .main-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    display: flex;
    align-items: center;
    color: var(--white);
    text-decoration: none;
    font-size: 1.5em;
    font-weight: bold;
    width: 200px;
  }
  
  .logo-image {
    height: 40px;
    width: 40px;
    margin-right: 10px;
  }
  
  .logo-text {
    width: 150px;
    white-space: nowrap;
  }
  
  .nav-list {
    display: flex;
    list-style-type: none;
  }
  
  .nav-item {
    margin: 0 15px;
  }
  
  .nav-link {
    color: var(--white);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .nav-link:hover {
    color: var(--primary-color);
  }
  
  .mobile-menu-btn {
    display: none;
    font-size: 1.5em;
    color: var(--white);
    background: none;
    border: none;
    cursor: pointer;
  }
  
  /* 主要内容样式 */
  .section {
    /* padding: 80px 40px; */
    margin-bottom: 40px;
  }
  
  .section-title {
    color: var(--secondary-color);
    margin-bottom: 20px;
  }
  
  .page-title {
    font-size: 2.5em;
    text-align: center;
  }
  
  .section-title {
    font-size: 2em;
    position: relative;
    padding-bottom: 10px;
  }
  
  .section-title::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50px;
    height: 3px;
    background-color: var(--primary-color);
  }
  
  .section-content {
    margin-bottom: 15px;
  }
  
  /* 优势网格样式 */
  .advantage-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  
  .advantage-item {
    background-color: var(--white);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer; /* 添加鼠标小手样式 */
  }
  
  .advantage-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  }
  
  .advantage-item h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: var(--primary-color);
  }
  
  /* 案例研究样式 */
  .case-studies {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
  
  .case-study {
    background-color: var(--white);
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer; /* 添加鼠标小手样式 */
  }
  
  .case-study img {
    width: 100%;
    height: 120px;
    object-fit: contain;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  /* 网红列表样式 */
  .influencer-disclaimer {
    font-style: italic;
    color: #666;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .influencer-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
  }
  
  .influencer-card {
    background-color: var(--white);
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    cursor: pointer; /* 添加鼠标小手样式 */
    text-decoration: none; /* 移除链接下划线 */
    color: inherit; /* 保持文本颜色不变 */
    display: block; /* 确保卡片仍然是块级元素 */
  }
  
  .influencer-card:hover {
    transform: translateY(-5px);
  }
  
  .influencer-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .influencer-name {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .influencer-followers {
    font-size: 14px;
    color: #666;
  }
  
  /* 页脚样式 */
  footer {
    background-color: var(--secondary-color);
    color: var(--white);
    text-align: center;
    padding: 20px 0;
    margin-top: 50px;
  }
  
  /* 响应式设计 */
  @media (max-width: 1200px) {
    .advantage-grid {
      grid-template-columns: repeat(3, 1fr);
    }
    
    .influencer-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  @media (max-width: 900px) {
    .advantage-grid {
      grid-template-columns: repeat(2, 1fr);
    }
    
    .influencer-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .advantage-grid,
    .case-studies {
      grid-template-columns: 1fr;
    }

    .influencer-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .nav-list {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: var(--secondary-color);
      padding: 0;
      margin: 0;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      z-index: 1000;
    }

    .nav-list.show {
      display: block;
    }

    .nav-item {
      margin: 0;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .nav-link {
      display: block;
      padding: 15px 20px;
      color: var(--white);
      text-align: left;
      transition: background-color 0.3s ease;
    }

    .nav-link:hover {
      background-color: rgba(255, 255, 255, 0.1);
      color: var(--primary-color);
    }

    .mobile-menu-btn {
      display: block;
      padding: 10px;
      background-color: transparent;
      border: none;
      color: var(--white);
      font-size: 1.5em;
      cursor: pointer;
      transition: color 0.3s ease;
    }

    .mobile-menu-btn:hover {
      color: var(--primary-color);
    }
  }

.contact-info {
  /* background-color: var(--white);
  border-radius: 5px;
  padding: 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center; */
}

.contact-link {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
  border-bottom: 1px solid var(--primary-color);
  padding-bottom: 2px;
}

.contact-link:hover {
  color: var(--secondary-color);
  border-bottom-color: var(--secondary-color);
}

/* 合作伙伴样式 */
.brand-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  margin-top: 30px;
}

.brand-item {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 25px 15px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer; /* 添加鼠标小手样式 */
}

.brand-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.brand-logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 10px;
}

.brand-name {
  font-size: 14px;
  color: var(--text-color);
  margin-top: 10px;
}

/* 响应式设计 */
@media (max-width: 1200px) {
  .brand-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 900px) {
  .brand-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 600px) {
  .brand-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

#about {
  background-image: url('https://my-netease-game-1258537270.cos.ap-singapore.myqcloud.com/sites/imgs/banner1.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  color: white;
  padding: 60px 0; /* 修改垂直内边距 */
}

#about::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

#about .section-title,
#about .content-area {
  position: relative;
  z-index: 2;
  max-width: 1200px; /* 添加最大宽度 */
  margin: 0 auto; /* 居中内容 */
  padding: 0 20px; /* 添加水平内边距 */
}

#about .section-title::after {
  background-color: white;
}

/* 地区筛选tab样式 */
.region-tabs {
  display: flex;
  /* justify-content: center; */
  margin-bottom: 40px;
  /* background-color: #f8f9fa; */
  /* border-radius: 50px; */
  padding: 10px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.region-tab {
  padding: 12px 25px;
  margin: 0 5px;
  border: none;
  background-color: transparent;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.region-tab:hover {
  background-color: rgba(52, 152, 219, 0.1);
  color: var(--primary-color);
}

.region-tab.active {
  background-color: var(--primary-color);
  color: var(--white);
  box-shadow: 0 4px 10px rgba(52, 152, 219, 0.3);
}

/* 响应式设计 */
@media (max-width: 768px) {
  .region-tabs {
    flex-wrap: wrap;
    border-radius: 25px;
    padding: 5px;
  }

  .region-tab {
    margin: 5px;
    padding: 10px 20px;
    font-size: 14px;
  }
}

/* 联系我们部分样式 */
.contact-content {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.contact-map,
.contact-info {
  flex: 1;
  min-width: 250px;
}

.contact-map img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
  }
}